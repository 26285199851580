import Offer from "../../../../../model/offer";
import { BaseScta } from "./base_scta";
import { SctaState } from "./scta_state";

export class SctaTest extends BaseScta implements SctaState {
  /**
   * @override
   */
  showSctaTriggered(offer: Offer): void {
    super.showSctaTriggered(offer);
    this.stateBuilder.setOfferId(offer.externalRefId);
  }
}
