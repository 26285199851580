import { BaseInteractionState, PullupState } from "../../index";

import offer from "../../../../../model/offer";
import {
  createElement,
  insertBodyContent,
} from "../../../../../../../service/dom/dom_utilities";
import State from "../../../../../state/state";
import DOMElements from "../../../../../../../service/dom/dom_elements";
import PageTypes from "../../../../../enum/page_types";

export abstract class BasePullup
  extends BaseInteractionState
  implements PullupState
{
  openPullup(offer: offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  showPullup(offer: offer): void {
    const content = this.service.composePullup(offer);
    this.service.pull(content);
    const pullupContainer = document.getElementsByClassName(
      "pi-pullup-container"
    );
    if (
      (this?.state?.pageType == PageTypes.SRP ||
        this?.state?.pageType == PageTypes.VDP) &&
      pullupContainer?.length > 0
    ) {
      //getting pullup text via a selector
      const tempDiv = createElement(DOMElements.div);
      tempDiv.innerHTML = offer.pullupOfferSource
        ? offer.pullupOfferSource
        : "";
      const pullupBtn =
        tempDiv?.querySelector(".pi_pull_up_button")?.textContent;
      const pullupText = tempDiv?.querySelector(
        ".pi-pullup-line1 span"
      )?.textContent;

      // Use a regular expression to extract the "$500" part
      let matchText = pullupText?.match(
        /\$[0-9]{1,}(,[0-9]{3})*(\.[0-9]{2})?/g
      );
      let matchButton = pullupBtn?.match(
        /\$[0-9]{1,}(,[0-9]{3})*(\.[0-9]{2})?/g
      );

      const fixedDiv = `<div style="
        position: fixed;
        width: 145px;
        top: 40%;
        transform: rotate(90deg);
        z-index: 2147483647;
        left: -50px;
        " class="_pi_rts_pullup_item left-fixed-div" data-offer-id="${
          offer?.externalRefId
        }">
        <div class="pi-pullup-template" style="text-align: center; color: white; background: red;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid black;
          -webkit-animation: bg-color-change 2s infinite;
          -moz-animation: bg-color-change 2s infinite;
          -o-animation: bg-color-change 2s infinite;
          -ms-animation: bg-color-change 2s infinite;
          animation: bg-color-change 2s infinite;">
        
          <div class="pi-pullup-line1">
              <span style="
                  color: white;
                  font-size: 20px;
                  font-weight: bold;
                  -webkit-animation: pi-color-change 2s infinite;
                  -moz-animation: pi-color-change 2s infinite;
                  -o-animation: pi-color-change 2s infinite;
                  -ms-animation: pi-color-change 2s infinite;
                  animation: pi-color-change 2s infinite;
                  cursor: pointer;
                  ">Get 
                  ${
                    matchText
                      ? matchText[0]
                      : matchButton
                      ? matchButton[0]
                      : " $500"
                  } Off
              </span>
          </div>
          <div class="pi-pullup-line2">
              <span style="
                  color: red;
                  font-size: 18px;
                  font-weight: normal;
                  -webkit-animation: pi-color-change 2s infinite;
                  -moz-animation: pi-color-change 2s infinite;
                  -o-animation: pi-color-change 2s infinite;
                  -ms-animation: pi-color-change 2s infinite;
                  animation: pi-color-change 2s infinite;
                  cursor: pointer;
                  ">
              
              </span>
          </div>
        </div>
      </div>`;

      insertBodyContent(fixedDiv);
    }
  }
}
