import { isNull, isObject, isUndefined } from "../../../../../service/lang";

import AppConfig from "../../../app.config";
import BaseDatetimeService from "../../../../../service/datetime/base/base_datetime_service";
import BaseGeolocation from "../../geolocation/base/base_geolocation";
import { BaseMasterConfig } from "../../../model/base/base_master_config";
import { BaseOfferEngagement } from "../../../model/base/base_offer_engagement";
import BaseStorageService from "../../../../../service/storage/base/base_storage_service";
import { ChatOptions } from "../../../manager/interaction/machines/states/chat/typedef";
import DatetimeService from "../../../../../service/datetime/datetime_service";
import DeviceTypes from "../../../enum/device_types";
import Duration from "../../../../../service/duration/duration";
import Factory from "../../../../../service/factory";
import Geolocation from "../../geolocation/geolocation";
import MasterConfig from "../../../model/master_config";
import Offer from "../../../model/offer";
import OfferEngagement from "../../../model/offer_engagement";
import PersistentChatWidgetState from "../../../../../persistent_chat/src/widget/persistent_chat_widget_state";
import StorageKeys from "../../../../../service/storage/storage_keys";
import StorageRegistrar from "../storage_registrar";
import StorageService from "../../../../../service/storage/storage_service";
import VisitorStatus from "../../../enum/visitor_status";
import VisitorInfo from "../../../model/visitor_info";
import { BaseVisitorInfo } from "../../../model/base/base_visitor_info";

export default class BaseStorageRegistrar implements StorageRegistrar {
  private _datetime: DatetimeService | undefined;
  private _storage: StorageService | undefined;

  protected get datetime(): DatetimeService {
    if (isUndefined(this._datetime)) {
      this._datetime = Factory.instance.build(BaseDatetimeService);
    }

    return this._datetime;
  }

  protected get storage(): StorageService {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageService);
    }

    return this._storage;
  }

  clearChatSession(): void {
    this.storage.deleteKey(StorageKeys.chatSession);
  }

  clearPendingChat(): void {
    this.storage.deleteKey(StorageKeys.pendingChat);
  }

  clearPendingOffer(): void {
    this.storage.deleteKey(StorageKeys.pendingOffer);
  }

  clearPersistentChatNetworkError(): void {
    this.storage.deleteKey(StorageKeys.chatNetworkError);
  }

  getAndExtend(key: StorageKeys, expiry: number): any {
    const data = this.storage.getKeyData(key);

    if (!isUndefined(data)) {
      this.storage.extendExpiry(key, expiry);
    }

    return data;
  }

  protected getAndExtendForSession(key: StorageKeys): any {
    const expiry = this.datetime.getFutureTimestamp(
      AppConfig.instance.sessionDuration
    );
    return this.getAndExtend(key, expiry);
  }

  getBlockedInteraction(): boolean {
    return this.storage.hasKey(StorageKeys.isInteractionBlocked);
  }

  getBlockedSent(): boolean | undefined {
    return this.getAndExtendForSession(StorageKeys.blockedSent);
  }

  getCampaign(): string | undefined {
    return this.storage.getKeyData(StorageKeys.campaign);
  }

  getChatSession(): ChatOptions {
    return this.storage.getKeyData(StorageKeys.chatSession);
  }

  getDealershipDeactivated(): boolean {
    return this.storage.getKeyData(StorageKeys.deactivated) ?? false;
  }

  getDealershipId(): string | undefined {
    return this.storage.getKeyData(StorageKeys.dealershipId);
  }

  getDeviceType(): DeviceTypes | undefined {
    return this.storage.getKeyData(StorageKeys.deviceType);
  }

  getEverVisitedOffer(): string | undefined {
    return this.getAndExtendForSession(StorageKeys.everVisitedOffer);
  }

  getFingerprint(): string | null | undefined {
    return this.getAndExtendForSession(StorageKeys.fingerprint);
  }

  getGeolocation(): Geolocation | null | undefined {
    let geolocation: Geolocation | null | undefined;
    const source: any = this.getAndExtendForSession(StorageKeys.geolocation);

    if (isObject(source)) {
      geolocation = new BaseGeolocation(source);
    } else if (isNull(source)) {
      geolocation = null;
    }

    return geolocation;
  }

  getMasterConfig(): MasterConfig | undefined {
    let config: MasterConfig | undefined;
    const source: any = this.getAndExtendForSession(StorageKeys.masterConifg);

    if (isObject(source)) {
      config = new BaseMasterConfig(source);
    }

    return config;
  }

  getOffers(): Offer[] {
    return this.getMasterConfig()?.offers ?? [];
  }

  getPendingChat(): ChatOptions {
    return this.storage.getKeyData(StorageKeys.pendingChat);
  }

  getPendingOffer(): OfferEngagement {
    const source = this.storage.getKeyData(StorageKeys.pendingOffer);
    return new BaseOfferEngagement(source);
  }

  getPersistentChatShownSent(): boolean | undefined {
    return this.storage.getKeyData(StorageKeys.persistentChatShownSent);
  }

  getPersistentChatWidgetState(): PersistentChatWidgetState {
    return (
      this.storage.getKeyData(StorageKeys.persistentChatWidgetState) ??
      PersistentChatWidgetState.opened
    );
  }

  getVisitorInfo(): VisitorInfo {
    const source: any = this.storage.getKeyData(StorageKeys.visitorInfo);

    return new BaseVisitorInfo(source);
  }

  getHasVisitorInfo(): VisitorInfo {
    const source: any = this.storage.getKeyData(StorageKeys.visitorInfo);
    return source;
  }

  getPullupSession(): boolean | undefined {
    return this.storage.getKeyData(StorageKeys.pullupSession);
  }

  getSessionId(): string | undefined {
    const expiry = this.datetime.getFutureTimestamp(
      AppConfig.instance.sessionDuration
    );
    this.storage.extendExpiry(StorageKeys.chatSession, expiry);
    this.storage.extendExpiry(StorageKeys.persistentChatShownSent, expiry);
    return this.getAndExtend(StorageKeys.sessionId, expiry);
  }

  getShownOffersCount(): number | undefined {
    return this.getAndExtendForSession(StorageKeys.shownOffersCount);
  }

  getMaximumSessionLength(): number | undefined {
    return this.getAndExtendForSession(StorageKeys.maxSessionLength);
  }

  getUniquePageViews(): number | undefined {
    return this.getAndExtendForSession(StorageKeys.uniquePageViews);
  }

  getVisitorConverted(): boolean | undefined {
    const current = this.storage.getKeyData(StorageKeys.visitorConverted);
    const legacy = !!this.storage.getKeyDataLegacy(
      StorageKeys.Old.visitorConverted
    );
    return current ?? legacy;
  }

  getIOfferFlowCompleted(): boolean | undefined {
    const current = this.storage.getKeyData(StorageKeys.iOfferFlowCompleted);
    return current;
  }

  getVisitorId(): string | undefined {
    const current = this.storage.getKeyData(StorageKeys.visitorId);
    const legacy = this.storage.getKeyDataLegacy(StorageKeys.Old.visitorId);
    return current || legacy;
  }

  getVisitorStatus(): VisitorStatus | undefined {
    return this.getAndExtendForSession(StorageKeys.visitorStatus);
  }

  getDealershipTs(): number | undefined {
    return this.storage.getKeyData(StorageKeys.dealershipTs);
  }

  hasCampaign(): boolean {
    return !isUndefined(this.getCampaign());
  }

  hasChatNetworkError(): boolean {
    return this.getAndExtendForSession(StorageKeys.chatNetworkError) === true;
  }

  hasChatSession(): boolean {
    return !isUndefined(this.storage.getKeyData(StorageKeys.chatSession));
  }

  hasDealershipId(): boolean {
    return !isUndefined(this.getDealershipId());
  }

  hasDeviceType(): boolean {
    return !isUndefined(this.getDeviceType());
  }

  hasMasterConfig(): boolean {
    return !isUndefined(this.getMasterConfig());
  }

  hasPendingChat(): boolean {
    return !isUndefined(this.storage.getKeyData(StorageKeys.pendingChat));
  }

  hasPendingOffer(): boolean {
    return !isUndefined(this.storage.getKeyData(StorageKeys.pendingOffer));
  }

  hasSessionId(): boolean {
    return !isUndefined(this.getSessionId());
  }

  hasVisitorId(): boolean {
    return !isUndefined(this.getVisitorId());
  }

  hasVisitorStatus(): boolean {
    return !isUndefined(this.getVisitorStatus());
  }

  setBlockedSent(): void {
    this.setForSession(StorageKeys.blockedSent, true);
  }

  setChatSession(update: ChatOptions): void {
    this.setForSession(StorageKeys.chatSession, update);
  }

  setCampaign(update: string): void {
    this.storage.setKey(StorageKeys.campaign, { data: update });
  }

  setDealershipDeactivated(): void {
    this.setForSession(StorageKeys.deactivated, true);
  }

  setDealershipId(update: string): void {
    this.storage.setKey(StorageKeys.dealershipId, { data: update });
  }

  setDeviceType(update: DeviceTypes): void {
    this.storage.setKey(StorageKeys.deviceType, { data: update });
  }

  setDealershipTs(update: number): void {
    this.storage.setKey(StorageKeys.dealershipTs, { data: update });
  }

  setEverVisitedOffer(update: string): void {
    this.setForSession(StorageKeys.everVisitedOffer, update);
  }

  setFingerprint(update: string | null): void {
    this.setForSession(StorageKeys.fingerprint, update);
  }

  protected setForSession(key: StorageKeys, data: any): void {
    const expiry = this.datetime.getFutureTimestamp(
      AppConfig.instance.sessionDuration
    );
    const value = { data: data, expiry: expiry };
    this.storage.setKey(key, value);
  }

  setGeolocation(update: Geolocation | null): void {
    const value = isNull(update) ? update : update.toJSON();
    this.setForSession(StorageKeys.geolocation, value);
  }

  setMasterConfig(update: MasterConfig): void {
    this.setForSession(StorageKeys.masterConifg, update.toJSON());
  }

  setPendingChat(update: ChatOptions): void {
    this.storage.setKey(StorageKeys.pendingChat, { data: update });
  }

  setPendingOffer(update: OfferEngagement): void {
    this.storage.setKey(StorageKeys.pendingOffer, { data: update.toJSON() });
  }

  setPersistentChatNetworkError(): void {
    this.setForSession(StorageKeys.chatNetworkError, true);
  }

  setPersistentChatShownSent(): void {
    this.setForSession(StorageKeys.persistentChatShownSent, true);
  }

  setPersistentChatWidgetState(update: PersistentChatWidgetState): void {
    this.storage.setKey(StorageKeys.persistentChatWidgetState, {
      data: update,
    });
  }

  setPullupSession(duration: Duration): void {
    const expiry = this.datetime.getFutureTimestamp(duration);
    this.storage.setKey(StorageKeys.pullupSession, {
      data: true,
      expiry: expiry,
    });
  }

  setSessionId(update: string): void {
    this.setForSession(StorageKeys.sessionId, update);
  }

  setShownOffersCount(update: any): void {
    this.setForSession(StorageKeys.shownOffersCount, update);
  }

  setMaximumSessionLength(update: any): void {
    this.setForSession(StorageKeys.maxSessionLength, update);
  }

  setStorageService(update: StorageService): void {
    this._storage = update;
  }

  setUniquePageViews(update: number): void {
    this.setForSession(StorageKeys.uniquePageViews, update);
  }

  setVisitorConverted(): void {
    const duration = AppConfig.instance.visitorConversionDuration;
    const expiry = this.datetime.getFutureTimestamp(duration);
    const value = { data: true, expiry: expiry };
    this.storage.setKey(StorageKeys.visitorConverted, value);
  }

  setIOfferFlowCompleted(): void {
    this.storage.setKey(StorageKeys.iOfferFlowCompleted, { data: true });
  }

  setVisitorId(update: string): void {
    this.storage.setKey(StorageKeys.visitorId, { data: update });
  }

  setVisitorStatus(update: VisitorStatus): void {
    this.setForSession(StorageKeys.visitorStatus, update);
  }

  setVisitorInfo(update: VisitorInfo): void {
    location.reload();
    this.storage.setKey(StorageKeys.visitorInfo, { data: update });
  }
}
