import {
  composedPathHasChatTextButton,
  composedPathHasClass,
  composedPathHasId,
  elementHasId,
  findBySelector,
  hasClass,
  isInDOM,
} from "../../../../service/dom/dom_utilities";

import BaseDOMService from "../../service/dom/base/base_dom_service";
import DOMService from "../../service/dom/dom_service";
import Factory from "../../../../service/factory";
import { isUndefined } from "../../../../service/lang";

namespace Interactions {
  class Helper {
    private static _dom: DOMService | undefined;

    private static get dom(): DOMService {
      if (isUndefined(this._dom)) {
        this._dom = Factory.instance.build(BaseDOMService);
      }

      return this._dom;
    }

    static getControl(selector: string): any {
      return findBySelector(selector);
    }

    static getPlaceholder(selector: string): string {
      return this.dom.getPlaceholder(selector);
    }

    static hasControl(selector: string): boolean {
      return isInDOM(selector);
    }

    static hasId(element: any, id: string): boolean {
      return elementHasId(element, id);
    }

    static pathHasClass(event: any, className: string): boolean {
      const elements = event.composedPath();
      return elements.some((e: any) => hasClass(e, className));
    }
  }

  export class ChatPermissionKeys {
    static readonly acceptButton = "rts-chat-permission-question-button";
    static readonly denyButton = "rts-chat-permission-close-svg";
    static readonly fadeOut = "rts-chat-fade-out";
    static readonly notifyCloseButton = "rts-chat-notify-close-svg";
    static readonly notifyContainer = "rts-chat-notify-container";
    static readonly permissionContainer = "rts-chat-permission-container";
    static readonly permissionContainerStyle = "_pi_rts_chat_permission_style";
    static readonly permissionModal = "rts-chat-permission-modal";
    static readonly push = "rts-chat-push";
  }

  export class ChatPermissionSelectors {
    static readonly acceptButton = `#${ChatPermissionKeys.acceptButton}`;
    static readonly denyButton = `#${ChatPermissionKeys.denyButton}`;
    static readonly notifyCloseButton = `#${ChatPermissionKeys.notifyCloseButton}`;
    static readonly notifyContainer = `#${ChatPermissionKeys.notifyContainer}`;
    static readonly permissionContainer = `#${ChatPermissionKeys.permissionContainer}`;
    static readonly permissionContainerStyle = `#${ChatPermissionKeys.permissionContainerStyle}`;
    static readonly permissionModal = `#${ChatPermissionKeys.permissionModal}`;
  }

  export class ModalKeys {
    static readonly container = "_pi_rts_modal_container";
  }

  export class ModalPlaceholders {
    static readonly content = "{{content}}";
    static readonly zIndex = "{{zIndex}}";
  }

  export class ModalSelectors {
    static readonly container = `#${ModalKeys.container}`;
  }

  export class PopupKeys {
    static readonly closeButton = "pi_pop_up_close_button";
    static readonly container = "_pi_popup_container";
    static readonly content = "pi_pop_up_content";
    static readonly controlsContainer = "pi_pop_up_input_fields";
    static readonly disclaimer = "pi_disclaimer";
    static readonly emailControl = "pi_pop_up_email_control";
    static readonly error = "pi_pop_up_errors_text";
    static readonly firstNameControl = "pi_pop_up_first_name_control";
    static readonly form = "pi_pop_up_form";
    static readonly lastNameControl = "pi_pop_up_last_name_control";
    static readonly phoneControl = "pi_pop_up_phone_control";
    static readonly submitButton = "pi_pop_up_submit_button";
    static readonly submitButtonMultiOne = "pi_pop_up_submit_button_multi_one";
    static readonly success = "pi_pop_up_success_text";
    static readonly termsAndConsClose =
      "pi_pop_up_terms_and_conditions_close_button";
    static readonly termsAndConsLink = "pi_pop_up_terms_and_conditions_link";
    static readonly termsAndConsReplace = "pi_terms_and_conditions_replace";
    static readonly termsAndConsText = "pi_pop_up_terms_and_conditions_text";
    static readonly zipControl = "pi_pop_up_zip_control";
    static readonly multiphaseOne = "multiphase-one-form";
    static readonly multiphaseTwo = "multiphase-two-form";
    static readonly sctaMainContainer = "pi_pop_up_main_container";
  }

  export class PopupPlaceholders {
    static readonly image = "{{pi_pop_up_bg}}";
    static readonly sctaImage = "{{scta_logo}}";
    static readonly offerName = "{{offerName}}";
  }

  export class PopupSelectors {
    static readonly closeButton = `.${PopupKeys.closeButton}`;
    static readonly container = `#${PopupKeys.container}`;
    static readonly content = `.${PopupKeys.content}`;
    static readonly crmControl = `.${PopupKeys.controlsContainer} select[name="crmEmail"]`;
    static readonly adfControl = `.${PopupKeys.controlsContainer} select[name="adfDetails"]`;
    static readonly disclaimer = `#${PopupKeys.disclaimer}`;
    static readonly emailControl = `.${PopupKeys.emailControl}`;
    static readonly error = `.${PopupKeys.error}`;
    static readonly firstNameControl = `.${PopupKeys.firstNameControl}`;
    static readonly form = `.${PopupKeys.form}`;
    static readonly lastNameControl = `.${PopupKeys.lastNameControl}`;
    static readonly phoneControl = `.${PopupKeys.phoneControl}`;
    static readonly submitButton = `.${PopupKeys.submitButton}`;
    static readonly submitButtonMultiOne = `.${PopupKeys.submitButtonMultiOne}`;
    static readonly success = `.${PopupKeys.success}`;
    static readonly termsAndConsClose = `.${PopupKeys.termsAndConsClose}`;
    static readonly termsAndConsLink = `.${PopupKeys.termsAndConsLink}`;
    static readonly termsAndConsReplace = `.${PopupKeys.termsAndConsReplace}`;
    static readonly termsAndConsText = `.${PopupKeys.termsAndConsText}`;
    static readonly zipControl = `.${PopupKeys.zipControl}`;
    static readonly multiphaseOne = `${PopupKeys.multiphaseOne}`;
    static readonly multiphaseTwo = `${PopupKeys.multiphaseTwo}`;
    static readonly sctaMainContainer = `.${PopupKeys.sctaMainContainer}`;
  }

  export class PullupKeys {
    static readonly container = "pi-pullup-container";
    static readonly item = "_pi_rts_pullup_item";
    static readonly offerIdDataKey = "offerId";
  }

  export class SctaKeys {
    static readonly sctaContainer = "scta-container";
    static readonly neutralBtn = "neutral-btn-template";
    static readonly triggeredBtn = "triggered-btn-template";
    static readonly sctaOffer = "single-cta-preview";
    static readonly sctaCloseBtn = "close-btn-scta";
    static readonly sctaImage = "scta-image";
    static readonly sctaButton = "scta-button";
    static readonly sctaBtnsDiv = "scta-buttons-div";
  }

  export class iOfferKeys {
    static readonly iOfferContainer = "pi-ioffer-container";
    static readonly vehicleTab = "banner_ymmt";
    static readonly vehicleTabModal = "banner_ymmt_modal";
    static readonly vinTab = "banner_vin";
    static readonly vinTabModal = "banner_vin_modal";
    static readonly plateTab = "banner_plate";
    static readonly plateTabModal = "banner_plate_modal";
    static readonly vehicleTabId = "bannerYmmt";
    static readonly vehicleTabIdModal = "bannerYmmtModal";
    static readonly closeX = "ioffer-search-close-modal";
    static readonly bannerVehicleItems = "banner-vehicle-items";
    static readonly bannerVehicleItemsModal = "banner-vehicle-items-modal";
    static readonly vinTabId = "bannerVin";
    static readonly vinTabIdModal = "bannerVinModal";
    static readonly plateTabId = "bannerPlate";
    static readonly plateTabIdModal = "bannerPlateModal";
    static readonly vehicleInput = "bannerYmmtView";
    static readonly vehicleInputModal = "bannerYmmtViewModal";
    static readonly vinInput = "bannerVinView";
    static readonly vinInputModal = "bannerVinViewModal";
    static readonly plateInput = "bannerPlateView";
    static readonly plateInputModal = "bannerPlateViewModal";
    static readonly vehicleInputText = "bannerInputTxt";
    static readonly vehicleInputTextModal = "bannerInputTxtModal";
    static readonly vinInputText = "vinNumberInput";
    static readonly vinInputTextModal = "vinNumberInputModal";
    static readonly plateInputText = "licNumberInput";
    static readonly plateInputTextModal = "licNumberInputModal";
    static readonly stateNumberInput = "stateNumberInput";
    static readonly stateNumberInputModal = "stateNumberInputModal";
    static readonly termsCheckbox = "terms-checkbox";
    static readonly searchVinView = "searchVinView";
    static readonly searchVinViewModal = "searchVinViewModal";
    static readonly vehicleDescriptionList = "vehicleDescriptionList";
    static readonly vehicleDescriptionListModal = "vehicleDescriptionListModal";
    static readonly vehicleDescriptionVinList = "vehicleDescriptionVinList";
    static readonly vehicleDescriptionVinListModal =
      "vehicleDescriptionVinListModal";
    static readonly vehicleDescriptionPlateList = "vehicleDescriptionPlateList";
    static readonly vehicleDescriptionPlateListModal =
      "vehicleDescriptionPlateListModal";
    static readonly iofferBackdrop = "ioffer-backdrop";
    static readonly iofferBackdropModal = "ioffer-backdrop-modal";
    static readonly iofferModal = "ioffer-modal";
    static readonly iofferModal2 = "ioffer-modal-2";
    static readonly iFrameModalId = "ioffer-iframe-1";
    static readonly iOfferFormDiv = "ioffer-form-main-div";
    static readonly termsText = "phone-text-terms";
  }

  export class iOfferSelectors {
    static readonly iOfferContainer = `.${iOfferKeys.iOfferContainer}`;
    static readonly vehicleInput = `.${iOfferKeys.vehicleInput}`;
    static readonly vehicleInputModal = `.${iOfferKeys.vehicleInputModal}`;
    static readonly vinInput = `.${iOfferKeys.vinInput}`;
    static readonly vinInputModal = `.${iOfferKeys.vinInputModal}`;
    static readonly plateInput = `.${iOfferKeys.plateInput}`;
    static readonly plateInputModal = `.${iOfferKeys.plateInputModal}`;
  }

  export class PrimaryBannerKeys {
    static readonly item = "_pi_rts_primary_banner_item";
  }

  export class PullupPlaceholders {
    static readonly offerId = "{{offerId}}";
    static readonly template = "{{template}}";
  }

  export class PullupSelectors {
    static readonly container = `.${PullupKeys.container}`;
  }

  export class SctaSelectors {
    static readonly sctaContainer = `.${SctaKeys.sctaContainer}`;
    static readonly sctaOffer = `#${SctaKeys.sctaOffer}`;
    static readonly sctaCloseBtn = `#${SctaKeys.sctaCloseBtn}`;
    static readonly sctaButton = `.${SctaKeys.sctaButton}`;
    static readonly sctaBtnsDiv = `.${SctaKeys.sctaBtnsDiv}`;
  }

  export class StyleKeys {
    static readonly buttonLoading = "_pi_rts_button_loading";
    static readonly container = "_pi_rts_style_container";
    static readonly disabled = "_pi_rts_disabled";
  }

  export class StyleSelectors {
    static readonly buttonLoading = `.${StyleKeys.buttonLoading}`;
    static readonly container = `#${StyleKeys.container}`;
    static readonly disabled = `.${StyleKeys.disabled}`;
  }

  export function getCRMPlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.crmControl);
  }

  export function getADFDetailsPlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.adfControl);
  }

  export function getDisclaimer(): any {
    return Helper.getControl(PopupSelectors.disclaimer);
  }

  export function getEmailPlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.emailControl);
  }

  export function getFirstNamePlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.firstNameControl);
  }

  export function getLastNamePlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.lastNameControl);
  }

  export function getPhonePlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.phoneControl);
  }

  export function hasCRMControl(): boolean {
    return Helper.hasControl(PopupSelectors.crmControl);
  }

  export function hasADFDetailsControl(): boolean {
    return Helper.hasControl(PopupSelectors.adfControl);
  }

  export function hasDisclaimer(): boolean {
    return Helper.hasControl(PopupSelectors.disclaimer);
  }

  export function hasEmailControl(): boolean {
    return Helper.hasControl(PopupSelectors.emailControl);
  }

  export function hasFirstNameControl(): boolean {
    return Helper.hasControl(PopupSelectors.firstNameControl);
  }

  export function hasLastNameControl(): boolean {
    return Helper.hasControl(PopupSelectors.lastNameControl);
  }

  export function hasPhoneControl(): boolean {
    return Helper.hasControl(PopupSelectors.phoneControl);
  }

  export function hasZIPControl(): boolean {
    return Helper.hasControl(PopupSelectors.zipControl);
  }

  export function isAcceptChat(event: any): boolean {
    return composedPathHasId(event, ChatPermissionKeys.acceptButton);
  }

  export function isSctaDiv(event: any): boolean {
    return composedPathHasClass(event, SctaKeys.sctaBtnsDiv);
  }

  export function isChatText(event: any): boolean {
    return composedPathHasChatTextButton(event);
  }

  export function isClickPullup(event: any): boolean {
    return composedPathHasClass(event, PullupKeys.item);
  }

  export function isClickNeutral(event: any): boolean {
    return composedPathHasClass(event, SctaKeys.neutralBtn);
  }

  export function isCloseScta(event: any): boolean {
    return composedPathHasId(event, SctaKeys.sctaCloseBtn);
  }

  export function isClickTriggered(event: any): boolean {
    return composedPathHasClass(event, SctaKeys.triggeredBtn);
  }

  export function isClickVehicleTab(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.vehicleTab);
  }

  export function isClickVehicleTabModal(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.vehicleTabModal);
  }

  export function isClickVinTab(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.vinTab);
  }

  export function isClickVinTabModal(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.vinTabModal);
  }

  export function isClickPlateTab(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.plateTab);
  }

  export function isClickPlateTabModal(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.plateTabModal);
  }

  export function isClickCloseX(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.closeX);
  }

  export function isClickVehicleItem(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.bannerVehicleItems);
  }

  export function isClickVehicleItemModal(event: any): boolean {
    return composedPathHasClass(event, iOfferKeys.bannerVehicleItemsModal);
  }

  export function isClickPrimaryBanner(event: any): boolean {
    return composedPathHasClass(event, PrimaryBannerKeys.item);
  }

  export function isClosePopup(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.closeButton);
  }

  export function isCloseTermsAndConditions(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.termsAndConsClose);
  }

  export function isDenyChat(event: any): boolean {
    return composedPathHasId(event, ChatPermissionKeys.denyButton);
  }

  export function isNotifyCloseButton(event: any): boolean {
    return composedPathHasId(event, ChatPermissionKeys.notifyCloseButton);
  }

  export function isOpenTermsAndConditions(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.termsAndConsLink);
  }

  export function isPhoneInput(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.phoneControl);
  }

  export function isSearchVehicleInput(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.vehicleInputText);
  }

  export function isSearchVehicleInputModal(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.vehicleInputTextModal);
  }

  export function isSearchVinInput(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.vinInputText);
  }

  export function isSearchVinInputModal(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.vinInputTextModal);
  }

  export function isSearchPlateInput(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.plateInputText);
  }

  export function isSearchPlateInputModal(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.plateInputTextModal);
  }

  export function isSelectPlateInput(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.stateNumberInput);
  }

  export function isSelectPlateInputModal(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.stateNumberInputModal);
  }

  export function isSubmitForm(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.submitButton);
  }

  export function isTermsClicked(event: any): boolean {
    return composedPathHasId(event, iOfferKeys.termsCheckbox);
  }

  export function isMultiOneForm(event: any): boolean {
    return composedPathHasId(event, PopupKeys.submitButtonMultiOne);
  }

  export function isSupported(event: any): boolean {
    return (
      isAcceptChat(event) ||
      isSctaDiv(event) ||
      isChatText(event) ||
      isClickPullup(event) ||
      isClickVehicleTab(event) ||
      isClickVehicleTabModal(event) ||
      isClickVinTab(event) ||
      isClickVinTabModal(event) ||
      isClickPlateTab(event) ||
      isClickPlateTabModal(event) ||
      isClickCloseX(event) ||
      isClickVehicleItem(event) ||
      isClickVehicleItemModal(event) ||
      isClickNeutral(event) ||
      isClickTriggered(event) ||
      isCloseScta(event) ||
      isSearchVehicleInput(event) ||
      isSearchVehicleInputModal(event) ||
      isSearchVinInput(event) ||
      isSearchVinInputModal(event) ||
      isSearchPlateInput(event) ||
      isSearchPlateInputModal(event) ||
      isSelectPlateInput(event) ||
      isSelectPlateInputModal(event) ||
      isClickPrimaryBanner(event) ||
      isClosePopup(event) ||
      isCloseTermsAndConditions(event) ||
      isDenyChat(event) ||
      isNotifyCloseButton(event) ||
      isOpenTermsAndConditions(event) ||
      isPhoneInput(event) ||
      isMultiOneForm(event) ||
      isSubmitForm(event) ||
      isTermsClicked(event)
    );
  }
}

export default Interactions;
