import { isString, isUndefined } from "../../../../../../service/lang";

import BaseLogger from "../../../../../../service/logger/base/base_logger";
import BaseStorageRegistrar from "../../../storage/base/base_storage_registrar";
import Factory from "../../../../../../service/factory";
import GoogleAnalytics from "../google_analytics";
import Logger from "../../../../../../service/logger/logger";
import StorageRegistrar from "../../../storage/storage_registrar";
import URLService from "../../../url/url_service";
import BaseURLService from "../../../url/base/base_url_service";

export default class BaseGoogleAnalytics implements GoogleAnalytics {
  private _logger: Logger | undefined;
  private _storage: StorageRegistrar | undefined;
  private _trackingId: string | undefined;
  private _trackingIds: string[] | undefined;
  private _url: URLService | undefined;

  protected readonly action = "asc_form_submission";
  protected readonly category = "myOffer Lead";
  protected readonly label = "myOffer Lead";
  protected readonly type = "event";
  protected readonly event_owner = "Triple Threatt";

  protected readonly configInfo = this.storage.getMasterConfig();
  protected readonly logCallback = (response: any) =>
    this.logger.info(response);
  protected readonly gaEvent = {
    eventCategory: this.category,
    eventOwner:
      this.configInfo?.dealership?.crmSubdomainName == "triplethreatt"
        ? this.event_owner
        : this.configInfo?.dealership?.crmSubdomainName ==
            "conversioniq_click_here_digital" ||
          this.configInfo?.dealership?.crmSubdomainName == "clickheredigital"
        ? "ConversioniQ_Click_Here_Digital"
        : "",
    eventAction: this.action,
    eventLabel: this.label,
    hitType: this.type,
    hitCallback: this.logCallback,
  };
  protected readonly gtagEvent = {
    event_category: this.category,
    event_label: this.label,
    event_owner:
      this.configInfo?.dealership?.crmSubdomainName == "triplethreatt"
        ? this.event_owner
        : this.configInfo?.dealership?.crmSubdomainName ==
            "conversioniq_click_here_digital" ||
          this.configInfo?.dealership?.crmSubdomainName == "clickheredigital"
        ? "ConversioniQ_Click_Here_Digital"
        : "PureInfluencer",
    value: 1,
    event_callback: this.logCallback,
  };

  protected get ga(): any {
    // @ts-ignore
    return window.ga;
  }

  protected get gtag(): any {
    // @ts-ignore
    return window.gtag;
  }

  protected get dataLayer(): any {
    // @ts-ignore
    return window.dataLayer;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  protected get trackingId(): string | undefined {
    if (isUndefined(this._trackingId)) {
      const config = this.storage.getMasterConfig();
      this._trackingId = config?.dealership?.googleAnalyticsTrackingId;
    }

    return this._trackingId;
  }

  protected get trackingIds(): string[] | undefined {
    if (isUndefined(this._trackingIds)) {
      const config = this.storage.getMasterConfig();
      const trackingId = config?.dealership?.googleAnalyticsTrackingId;
      const trackingIds = trackingId?.split(",");
      this._trackingIds =
        trackingIds &&
        (trackingIds?.length > 1
          ? trackingIds.map((element) => element.replace(/ /g, ""))
          : trackingIds);
    }

    return this._trackingIds;
  }

  sendSctaLead(ascEvent: string): void {
    let sctaGaEvent = {
      eventCategory: this.category,
      eventOwner:
        this.configInfo?.dealership?.crmSubdomainName == "triplethreatt"
          ? this.event_owner
          : this.configInfo?.dealership?.crmSubdomainName ==
              "conversioniq_click_here_digital" ||
            this.configInfo?.dealership?.crmSubdomainName == "clickheredigital"
          ? "ConversioniQ_Click_Here_Digital"
          : "",
      eventAction: ascEvent,
      eventLabel: this.label,
      hitType: this.type,
      hitCallback: this.logCallback,
    };

    let sctaGtagEvent = {
      event_category: this.category,
      event_label: this.label,
      event_owner:
        this.configInfo?.dealership?.crmSubdomainName == "triplethreatt"
          ? this.event_owner
          : this.configInfo?.dealership?.crmSubdomainName ==
              "conversioniq_click_here_digital" ||
            this.configInfo?.dealership?.crmSubdomainName == "clickheredigital"
          ? "ConversioniQ_Click_Here_Digital"
          : "PureInfluencer",
      value: 1,
      event_callback: this.logCallback,
    };
    this.trackingIds?.map((trackId) => {
      console.log("TrackID: ", trackId);
      try {
        this.ga("create", trackId, "auto", "myo_pigat");
        this.ga("myo_pigat.send", sctaGaEvent);
        console.log("GA event: ", sctaGaEvent);
        try {
          this.dataLayer.push({
            ...sctaGtagEvent,
            event: ascEvent,
            config: trackId,
          });
          console.log("GTM event: ", {
            ...sctaGtagEvent,
            event: ascEvent,
            config: trackId,
          });
          try {
            let sctaGtagEventX = {
              ...sctaGtagEvent,
              send_to: trackId,
            };
            this.gtag("event", ascEvent, sctaGtagEventX);
            console.log("GTAG event : ", sctaGtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        } catch (_error) {
          this.logger.warning(
            "Could not send lead event through GTM Datalayer!"
          );
          try {
            let sctaGtagEventX = {
              ...sctaGtagEvent,
              send_to: trackId,
            };
            this.gtag("event", ascEvent, sctaGtagEventX);
            console.log("GTAG event no GTM: ", sctaGtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        }
      } catch (_error) {
        this.logger.warning(
          "Could not send lead event through Google Analytics!"
        );
        try {
          this.dataLayer.push({
            ...sctaGtagEvent,
            event: ascEvent,
            config: trackId,
          });
          console.log("GTM event no GA: ", {
            ...sctaGtagEvent,
            event: ascEvent,
          });
          try {
            let sctaGtagEventX = {
              ...sctaGtagEvent,
              send_to: trackId,
            };
            this.gtag("event", ascEvent, sctaGtagEventX);
            console.log("GTAG event with GTM no GA: ", sctaGtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        } catch (_error) {
          this.logger.warning(
            "Could not send lead event through GTM Datalayer!"
          );
          try {
            let sctaGtagEventX = {
              ...sctaGtagEvent,
              send_to: trackId,
            };
            this.gtag("event", ascEvent, sctaGtagEventX);
            console.log("GTAG event no GTM no GA: ", sctaGtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        }
      }
    });
  }

  sendLead(): void {
    this.trackingIds?.map((trackId) => {
      console.log("TrackID: ", trackId);
      try {
        this.ga("create", trackId, "auto", "myo_pigat");
        this.ga("myo_pigat.send", this.gaEvent);
        console.log("GA event: ", this.gaEvent);
        try {
          this.dataLayer.push({
            ...this.gtagEvent,
            event: "asc_form_submission",
            config: trackId,
          });
          console.log("GTM event: ", {
            ...this.gtagEvent,
            event: "asc_form_submission",
            config: trackId,
          });
          try {
            let gtagEventX = {
              ...this.gtagEvent,
              send_to: trackId,
            };
            this.gtag("event", "asc_form_submission", gtagEventX);
            console.log("GTAG event : ", gtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        } catch (_error) {
          this.logger.warning(
            "Could not send lead event through GTM Datalayer!"
          );
          try {
            let gtagEventX = {
              ...this.gtagEvent,
              send_to: trackId,
            };
            this.gtag("event", "asc_form_submission", gtagEventX);
            console.log("GTAG event no GTM: ", gtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        }
      } catch (_error) {
        this.logger.warning(
          "Could not send lead event through Google Analytics!"
        );
        try {
          this.dataLayer.push({
            ...this.gtagEvent,
            event: "asc_form_submission",
            config: trackId,
          });
          console.log("GTM event no GA: ", {
            ...this.gtagEvent,
            event: "asc_form_submission",
          });
          try {
            let gtagEventX = {
              ...this.gtagEvent,
              send_to: trackId,
            };
            this.gtag("event", "asc_form_submission", gtagEventX);
            console.log("GTAG event with GTM no GA: ", gtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        } catch (_error) {
          this.logger.warning(
            "Could not send lead event through GTM Datalayer!"
          );
          try {
            let gtagEventX = {
              ...this.gtagEvent,
              send_to: trackId,
            };
            this.gtag("event", "asc_form_submission", gtagEventX);
            console.log("GTAG event no GTM no GA: ", gtagEventX);
          } catch (_error) {
            this.logger.warning(
              "Could not send lead event through Google Tag Manager!"
            );
          }
        }
      }
    });
  }

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }
  //mitsubishi specific events

  protected readonly event_name_category = "form_complete";
  protected readonly event_action = "pureinfluencer - pop up form";
  protected readonly RGXT_CTA_Actions = "SUBMIT";
  protected readonly page_URL = this.url.getCurrentURL() ?? "";
  protected readonly page_Hostname =
    this.url.getCurrentUrlObj()?.hostname ?? "";
  protected readonly nameplate = "Page URL based Vehicle Name"; //variable
  protected readonly page_Path = this.url.getCurrentPath() ?? "";
  protected readonly LUT_Form_Labels = "complete"; //variable

  protected readonly mitsubishiGaEvent = {
    eventCategory: this.event_name_category,
    eventAction: this.event_action,
    eventLabel: this.LUT_Form_Labels, //{{LUT-Form-Labels}} - {{Page URL based Vehicle Name}} this has to be variable
  };

  protected readonly mitsubishiGtagEvent = {
    event: this.event_name_category,
    form_name: this.event_action,
    field_name: this.RGXT_CTA_Actions,
    url: this.page_URL,
    domain: this.page_Hostname,
    nameplate: this.nameplate, //set before function
    page_path: this.page_Path,
    form_category: "lead",
    label: this.LUT_Form_Labels, //'complete' should be variable too
    action: this.event_action,
  };

  protected readonly mitsubishiGtagEvent1 = {
    event: this.event_name_category,
    form_name: this.event_action,
    field_name: this.RGXT_CTA_Actions,
    url: this.page_URL,
    domain: this.page_Hostname,
    nameplate: this.nameplate, //set before function
    page_path: this.page_Path,
    form_category: "lead",
    label: this.LUT_Form_Labels, //'complete' should be variable too
    action: this.event_action,
  };

  protected readonly mitsubishiGtagEvent2 = {
    event: this.event_name_category,
    form_name: this.event_action,
    field_name: this.RGXT_CTA_Actions,
    url: this.page_URL,
    domain: this.page_Hostname,
    nameplate: this.nameplate, //set before function
    page_path: this.page_Path,
    form_category: "lead",
    label: this.LUT_Form_Labels, //'complete' should be variable too
    action: this.event_action,
  };

  sendMitsubishiLead(lead: any): void {
    this.trackingIds?.map((trackId) => {
      console.log("TrackID (Mitsubishi): ", trackId);
      let mitsubishiEventGa = {
        ...this.mitsubishiGaEvent,
        eventLabel: "complete - " + lead?.model,
      };
      let mitsubishiEventGtag = {
        ...this.mitsubishiGtagEvent,
        nameplate: lead?.model,
        label: "complete - " + lead?.model,
      };
      try {
        this.ga("create", trackId, "auto", "myo_pigat");
        this.ga("myo_pigat.send", mitsubishiEventGa);
        console.log("GA event (Mitsubishi): ", mitsubishiEventGa);
        try {
          this.dataLayer.push({
            ...mitsubishiEventGtag,
            config: trackId,
          });
          console.log("GTM event (Mitsubishi): ", {
            ...mitsubishiEventGtag,
            config: trackId,
          });
          try {
            let mitsubishiEventGtagX = {
              ...mitsubishiEventGtag,
              send_to: trackId,
            };
            this.gtag("event", "form_complete", mitsubishiEventGtagX);
            console.log("GTAG event (Mitsubishi): ", mitsubishiEventGtagX);
          } catch (_error) {
            this.logger.warning(
              "Could not send (Mitsubishi) lead event through Google Tag Manager!"
            );
          }
        } catch (_error) {
          this.logger.warning(
            "Could not send (Mitsubishi) lead event through GTM Datalayer!"
          );
          try {
            let mitsubishiEventGtagX = {
              ...mitsubishiEventGtag,
              send_to: trackId,
            };
            this.gtag("event", "form_complete", mitsubishiEventGtagX);
            console.log(
              "GTAG event no GTM (Mitsubishi): ",
              mitsubishiEventGtagX
            );
          } catch (_error) {
            this.logger.warning(
              "Could not send (Mitsubishi) lead event through Google Tag Manager!"
            );
          }
        }
      } catch (_error) {
        this.logger.warning(
          "Could not send (Mitsubishi) lead event through Google Analytics!"
        );
        try {
          this.dataLayer.push({
            ...mitsubishiEventGtag,
            config: trackId,
          });
          console.log("GTM event no GA (Mitsubishi): ", {
            ...mitsubishiEventGtag,
          });
          try {
            let mitsubishiEventGtagX = {
              ...mitsubishiEventGtag,
              send_to: trackId,
            };
            this.gtag("event", "form_complete", mitsubishiEventGtagX);
            console.log(
              "GTAG event with GTM no GA (Mitsubishi): ",
              mitsubishiEventGtagX
            );
          } catch (_error) {
            this.logger.warning(
              "Could not send (Mitsubishi) lead event through Google Tag Manager!"
            );
          }
        } catch (_error) {
          this.logger.warning(
            "Could not send (Mitsubishi) lead event through GTM Datalayer!"
          );
          try {
            let mitsubishiEventGtagX = {
              ...mitsubishiEventGtag,
              send_to: trackId,
            };
            this.gtag("event", "form_complete", mitsubishiEventGtagX);
            console.log(
              "GTAG event no GTM no GA (Mitsubishi): ",
              mitsubishiEventGtagX
            );
          } catch (_error) {
            this.logger.warning(
              "Could not send (Mitsubishi) lead event through Google Tag Manager!"
            );
          }
        }
      }
    });
  }
}
