import DOMElements from "../../../../../../../service/dom/dom_elements";
import {
  addClass,
  createElement,
  findById,
  hide,
  removeClass,
  setBorderBottomColor,
  setColor,
  setContent,
  show,
  showFlex,
} from "../../../../../../../service/dom/dom_utilities";
import { isUndefined } from "../../../../../../../service/lang";
import AppConfig from "../../../../../app.config";
import { IOfferBannerHTML } from "../../../../../iOfferWidget/bannerHTML";
import { iOfferFormPage1 } from "../../../../../iOfferWidget/iOfferFormPage1";
import Offer from "../../../../../model/offer";
import State from "../../../../../state/state";
import Interactions from "../../../interactions";
import { BaseInteractionState } from "../base_interaction_state";

export abstract class iOfferBanner extends BaseInteractionState {
  private _dealerCode: number | undefined =
    this.storage?.getMasterConfig()?.iOfferDealerId;
  // change dev and prod based on property + dealerCode
  private _APIURL:
    | string
    | undefined = `https://${AppConfig.instance.envVar}-api.ioffer.io/Services/v2`;
  private _bannerUrl:
    | string
    | undefined = `https://${AppConfig.instance.envVar}-banner.ioffer.io`;
  private _selectedVinNumber: string | undefined;
  private _vehicleData: any | undefined = [];
  private height = window.innerHeight - 60;
  private _iframe: any | undefined;
  private _exactId: any | undefined;
  private _selectedTab: number = 1;
  private vinNumberInputTxt = document.getElementById("vinNumberInput");
  private _lastPaste: any | undefined = Date.now();
  private debounceTimer: ReturnType<typeof setTimeout> | null = null;

  get dealerCode(): number | undefined {
    const config = this.storage.getMasterConfig();
    const dealerId = config?.iOfferDealerId;
    this._dealerCode = dealerId ?? undefined;
    return this._dealerCode;
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  protected get formData(): any {
    return this.service.getFormData();
  }

  get isIOfferFlowCompleted(): boolean {
    return this.storage.getIOfferFlowCompleted() === true;
  }

  get APIURL(): string {
    if (isUndefined(this._APIURL)) {
      throw new Error("API URL not set!");
    }
    this._APIURL = `https://${AppConfig.instance.envVar}-api.ioffer.io/Services/v2`;
    return this._APIURL;
  }

  get selectedVinNumber(): string {
    if (isUndefined(this._selectedVinNumber)) {
      return "";
    }
    return this._selectedVinNumber;
  }

  private setSelectedVinNumber(data: string): void {
    this._selectedVinNumber = data;
  }

  get vehicleData(): any[] {
    if (isUndefined(this._vehicleData)) {
      throw new Error("Vehicle Data not set!");
    }
    return this._vehicleData;
  }

  private setVehicleData(data: any): void {
    this._vehicleData = data;
  }

  get iframe(): any {
    if (isUndefined(this._iframe)) {
      throw new Error("Iframe not set!");
    }
    return this._iframe;
  }

  private setIframe(data: any): void {
    this._iframe = data;
  }

  get exactId(): any {
    if (isUndefined(this._exactId)) {
      // throw new Error("Exact Id not set!");
      return undefined;
    }
    return this._exactId;
  }

  private setExactId(data: any): void {
    this._exactId = data;
  }

  get selectedTab(): number {
    if (isUndefined(this._selectedTab)) {
      throw new Error("Selected tab not set!");
    }
    return this._selectedTab;
  }

  private setSelectedTab(tab: number): void {
    this._selectedTab = tab;
  }

  get lastPaste(): any {
    if (isUndefined(this._lastPaste)) {
      throw new Error("Last Paste is undefined!");
    }
    return this._lastPaste;
  }

  private setLastPaste(data: any): void {
    this._lastPaste = data;
  }

  get bannerUrl(): string {
    if (isUndefined(this._bannerUrl)) {
      throw new Error("Banner URL not set!");
    }
    // change dev and prod based on property + dealerCode
    const envVar = AppConfig.instance.envVar;
    this._bannerUrl = `https://${envVar}-banner.ioffer.io`;
    return this._bannerUrl;
  }

  showBanner(): void {
    const bannerSourceIOffer = IOfferBannerHTML;
    this.service.showIOfferBanner(bannerSourceIOffer);
  }

  openVehicleTabModal(offer?: Offer): void {
    show(findById(Interactions.iOfferKeys.vehicleInputModal));
    hide(findById(Interactions.iOfferKeys.vinInputModal));
    hide(findById(Interactions.iOfferKeys.plateInputModal));

    addClass(
      findById(Interactions.iOfferKeys.vehicleInputModal),
      "selected-btn"
    );
    removeClass(
      findById(Interactions.iOfferKeys.vinInputModal),
      "selected-btn"
    );
    removeClass(
      findById(Interactions.iOfferKeys.plateInputModal),
      "selected-btn"
    );

    addClass(
      findById(Interactions.iOfferKeys.vehicleTabIdModal),
      "selected-btn"
    );
    offer &&
      setColor(
        findById(Interactions.iOfferKeys.vehicleTabIdModal),
        offer?.template?.multiphaseMainColor ?? "#2fc072"
      );
    offer &&
      setBorderBottomColor(
        findById(Interactions.iOfferKeys.vehicleTabIdModal),
        offer?.template?.multiphaseMainColor ?? "#2fc072"
      );
    removeClass(
      findById(Interactions.iOfferKeys.vinTabIdModal),
      "selected-btn"
    );
    setColor(
      findById(Interactions.iOfferKeys.vinTabIdModal),
      "rgba(0, 0, 0, 0.5)"
    );
    removeClass(
      findById(Interactions.iOfferKeys.plateTabIdModal),
      "selected-btn"
    );
    setColor(
      findById(Interactions.iOfferKeys.plateTabIdModal),
      "rgba(0, 0, 0, 0.5)"
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal),
      ""
    );
    this.setSelectedTab(1);
  }

  openVinTabModal(offer?: Offer): void {
    show(findById(Interactions.iOfferKeys.vinInputModal));
    hide(findById(Interactions.iOfferKeys.vehicleInputModal));
    hide(findById(Interactions.iOfferKeys.plateInputModal));

    addClass(findById(Interactions.iOfferKeys.vinInputModal), "selected-btn");
    removeClass(
      findById(Interactions.iOfferKeys.vehicleInputModal),
      "selected-btn"
    );
    removeClass(
      findById(Interactions.iOfferKeys.plateInputModal),
      "selected-btn"
    );

    addClass(findById(Interactions.iOfferKeys.vinTabIdModal), "selected-btn");
    offer &&
      setColor(
        findById(Interactions.iOfferKeys.vinTabIdModal),
        offer?.template?.multiphaseMainColor ?? "#2fc072"
      );
    offer &&
      setBorderBottomColor(
        findById(Interactions.iOfferKeys.vinTabIdModal),
        offer?.template?.multiphaseMainColor ?? "#2fc072"
      );
    removeClass(
      findById(Interactions.iOfferKeys.vehicleTabIdModal),
      "selected-btn"
    );
    setColor(
      findById(Interactions.iOfferKeys.vehicleTabIdModal),
      "rgba(0, 0, 0, 0.5)"
    );
    removeClass(
      findById(Interactions.iOfferKeys.plateTabIdModal),
      "selected-btn"
    );
    setColor(
      findById(Interactions.iOfferKeys.plateTabIdModal),
      "rgba(0, 0, 0, 0.5)"
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal),
      ""
    );
    this.setSelectedTab(2);
  }

  openPlateTabModal(offer?: Offer): void {
    show(findById(Interactions.iOfferKeys.plateInputModal));
    hide(findById(Interactions.iOfferKeys.vehicleInputModal));
    hide(findById(Interactions.iOfferKeys.vinInputModal));

    addClass(findById(Interactions.iOfferKeys.plateInputModal), "selected-btn");
    removeClass(
      findById(Interactions.iOfferKeys.vehicleInputModal),
      "selected-btn"
    );
    removeClass(
      findById(Interactions.iOfferKeys.vinInputModal),
      "selected-btn"
    );

    addClass(findById(Interactions.iOfferKeys.plateTabIdModal), "selected-btn");
    offer &&
      setColor(
        findById(Interactions.iOfferKeys.plateTabIdModal),
        offer?.template?.multiphaseMainColor ?? "#2fc072"
      );
    offer &&
      setBorderBottomColor(
        findById(Interactions.iOfferKeys.plateTabIdModal),
        offer?.template?.multiphaseMainColor ?? "#2fc072"
      );
    removeClass(
      findById(Interactions.iOfferKeys.vehicleTabIdModal),
      "selected-btn"
    );
    setColor(
      findById(Interactions.iOfferKeys.vehicleTabIdModal),
      "rgba(0, 0, 0, 0.5)"
    );
    removeClass(
      findById(Interactions.iOfferKeys.vinTabIdModal),
      "selected-btn"
    );
    setColor(
      findById(Interactions.iOfferKeys.vinTabIdModal),
      "rgba(0, 0, 0, 0.5)"
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal),
      ""
    );
    this.setSelectedTab(3);
  }

  handleVehicleInputModal(element: any): void {
    try {
      // Clear the existing timer if it exists
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      // Set a new timer for 1 second
      this.debounceTimer = setTimeout(() => {
        fetch(this?.APIURL + "/Sellitifi/Appraisal/ListTrims", {
          method: "POST",
          body: JSON.stringify({ SearchString: element?.value }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            AppName: "DbSYC",
            Authorization: "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9",
          },
        })
          .then((e) => e.json())
          .then((e) => {
            this.setVehicleData(e.ListInfo);
            let t = "";
            e?.ListInfo?.forEach(
              (e: { VehicleDescription: any; CodeId: any }) => {
                if (e.VehicleDescription) {
                  t += `<div class="banner-vehicle-items-modal" value="${e?.CodeId}" style="padding: 5px 10px;font-family: Open Sans, sans-serif;cursor:pointer;">${e.VehicleDescription}</div>`;
                }
              }
            );
            hide(findById(Interactions.iOfferKeys.searchVinViewModal));
            show(findById(Interactions.iOfferKeys.vehicleDescriptionListModal));
            setContent(
              findById(Interactions.iOfferKeys.vehicleDescriptionListModal),
              t
            );
          })
          .catch(function (e) {
            console.error("Error while fetching vehicle trims:", e);
          });
      }, 1000); // Delay execution by 1 second (1000ms)
    } catch (t) {
      console.error("Error in handleVehicleInputModal:", t);
    }
  }

  closeModal(): void {
    this.setExactId(null);
    let backdropModal = findById(Interactions.iOfferKeys.iofferBackdropModal);
    hide(backdropModal);
    if (!this.isIOfferFlowCompleted) {
      findById(Interactions.iOfferKeys.vehicleInputTextModal).value = "";
      findById(Interactions.iOfferKeys.vinInputTextModal).value = "";
      findById(Interactions.iOfferKeys.plateInputTextModal).value = "";
      findById(Interactions.iOfferKeys.stateNumberInputModal).value = "";
    }

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal),
      ""
    );
    let iofferModal = findById(Interactions.iOfferKeys.iofferModal2);
    let iframe = findById(Interactions.iOfferKeys.iFrameModalId);
    iofferModal?.removeChild(iframe);
  }

  private escapeHtmlEntities(str: string) {
    return str
      .replace(/&/g, "%26")
      .replace(/</g, "%3C")
      .replace(/>/g, "%3E")
      .replace(/%/g, "%25")
      .replace(/\+/g, "%2B")
      .replace(/\$/g, "%24")
      .replace(/'/g, "%60")
      .replace(/{/g, "%7B")
      .replace(/}/g, "%7D")
      .replace(/\^/g, "%5E")
      .replace(/=/g, "%3D")
      .replace(/#/g, "%23");
  }

  private loadBanner(e: string) {
    //send all data from first submit, to banner submit
    const config = this.storage.getMasterConfig();
    const dealerId = config?.iOfferDealerId;

    let backdropModal = findById(Interactions.iOfferKeys.iofferBackdropModal);
    show(backdropModal);
    this.service.showIOfferForm(iOfferFormPage1);
    let iframeElem = createElement(DOMElements.iframe);
    iframeElem.frameBorder = 0;
    iframeElem.width = "100%";
    iframeElem.height = "100%";
    iframeElem.id = Interactions.iOfferKeys.iFrameModalId;
    iframeElem.setAttribute(
      "src",
      `${this.bannerUrl}/dealer/${this.dealerCode ?? dealerId}/${
        this.exactId
      }/${e}?PIDealerId=${this?.state?.dealershipId}&PIVisitorId=${
        this?.state?.visitorId
      }&firstName=${this?.formData?.first_name}&lastName=${
        this?.formData?.last_name
      }&email=${this.escapeHtmlEntities(this?.formData?.email)}&phone=${
        this?.formData?.phone
      }&zipCode=${this?.state?.geolocation?.zip}&isFromEmail=1`
    );
    let iofferModal = findById(Interactions.iOfferKeys.iofferModal2);
    iofferModal.appendChild(iframeElem);
  }

  selectItemModal(e: any): void {
    let value = e?.target?.getAttribute("value");
    let t = this?.vehicleData?.find((t) => t.CodeId == value);
    findById(Interactions.iOfferKeys.vehicleInputTextModal).value = t
      ? t?.VehicleDescription
      : "";

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal),
      ""
    );

    hide(findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal));
    setContent(
      findById(Interactions.iOfferKeys.vehicleDescriptionPlateListModal),
      ""
    );
    this.setExactId(value);
    this.loadBanner(
      this?.selectedVinNumber !== "" && 1 != this?.selectedTab
        ? this.selectedVinNumber
        : "VIN"
    );
  }

  private getVin(e: any) {
    try {
      fetch(this?.APIURL + "/Sellitifi/Appraisal/GetMatchVin", {
        method: "POST",
        body: JSON.stringify({ Vin: e, ZipCode: "", Mileage: "" }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          AppName: "DbSYC",
          Authorization: "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9",
        },
      })
        .then((e) => e.json())
        .then((t) => {
          hide(findById(Interactions.iOfferKeys.searchVinViewModal));
          if (t && t?.ExactList && t?.ExactList?.length) {
            if (!this.exactId) {
              this.setSelectedVinNumber(e);
              if (1 == t.ExactList.length) {
                this.setExactId(t.ExactList[0].ExactId);
                this.loadBanner(e);
              } else {
                this.setVehicleData(t.ExactList);
                let n = "";
                t?.ExactList?.forEach(
                  (e: { ExactId: any; VehicleDescription: any }) => {
                    e.VehicleDescription &&
                      (n += `<div class="banner-vehicle-items-modal" value="${e?.ExactId}" style="padding: 5px 10px;font-family: Open Sans, sans-serif;cursor:pointer;">${e.VehicleDescription}</div>`);
                  }
                );
                hide(findById(Interactions.iOfferKeys.searchVinViewModal));
                show(
                  findById(
                    Interactions.iOfferKeys.vehicleDescriptionVinListModal
                  )
                );
                setContent(
                  findById(
                    Interactions.iOfferKeys.vehicleDescriptionVinListModal
                  ),
                  n
                );
              }
            }
          } else {
            setContent(
              findById(Interactions.iOfferKeys.vehicleDescriptionVinListModal),
              ""
            );
            alert("Vin not found. Please re-enter it.");
          }
        })
        .catch(function (e) {});
    } catch (t) {}
  }

  private searchVin() {
    let e = findById(Interactions.iOfferKeys.vinInputTextModal)?.value;
    e &&
      17 == e.length &&
      (showFlex(findById(Interactions.iOfferKeys.searchVinViewModal)),
      this.getVin(e));
  }

  handleVinInputModal(element: any): void {
    if (!(Date.now() - this.lastPaste < 1e3)) {
      let t = element?.keyCode;
      if (
        (t > 47 && t < 58) ||
        (t > 64 && t < 91) ||
        (t > 96 && t < 123) ||
        8 == t
      )
        this.searchVin();
    }
  }

  private getVinFromPlates() {
    // Get the plate input and state input values
    const licensePlate = findById(
      Interactions.iOfferKeys.plateInputTextModal
    ).value;
    const licenseState = findById(
      Interactions.iOfferKeys.stateNumberInputModal
    ).value;

    try {
      // Clear the existing debounce timer if it exists
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      // Set a new debounce timer
      this.debounceTimer = setTimeout(() => {
        // Make the API call only after the user stops interacting for 1 second
        fetch(this?.APIURL + "/Sellitifi/Appraisal/GetMatchPlates", {
          method: "POST",
          body: JSON.stringify({
            LicensePlate: licensePlate,
            LicenseState: licenseState,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            AppName: "DbSYC",
            Authorization: "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data && data.ExactList && data.ExactList.length) {
              this.setSelectedVinNumber(data.Vin ? data.Vin : "VIN");
              if (data.ExactList.length === 1) {
                this.setExactId(data.ExactList[0].ExactId);
                this.loadBanner(this.selectedVinNumber);
              } else {
                this.setVehicleData(data.ExactList);
                let contentHtml = "";
                data.ExactList.forEach(
                  (item: { VehicleDescription: any; ExactId: any }) => {
                    if (item.VehicleDescription) {
                      contentHtml += `<div value="${item?.ExactId}" class="banner-vehicle-items-modal" style="padding: 5px 10px;font-family: Open Sans, sans-serif;cursor:pointer;">${item.VehicleDescription}</div>`;
                    }
                  }
                );

                hide(findById(Interactions.iOfferKeys.searchVinViewModal));
                show(
                  findById(
                    Interactions.iOfferKeys.vehicleDescriptionPlateListModal
                  )
                );
                setContent(
                  findById(
                    Interactions.iOfferKeys.vehicleDescriptionPlateListModal
                  ),
                  contentHtml
                );
              }
            } else {
              hide(findById(Interactions.iOfferKeys.searchVinViewModal));
              alert("License plate not found. Please re-enter it.");
            }
          })
          .catch((error) => {
            console.error("Error fetching VIN from plates:", error);
          });
      }, 1000); // Delay the API call by 1 second
    } catch (error) {
      console.error("Error in getVinFromPlates:", error);
    }
  }

  handlePlateInputModal(element: any): void {
    let e = findById(Interactions.iOfferKeys.plateInputTextModal).value,
      t = findById(Interactions.iOfferKeys.stateNumberInputModal).value;
    e &&
      t &&
      e.length > 5 &&
      (showFlex(findById(Interactions.iOfferKeys.searchVinViewModal)),
      this.getVinFromPlates());
  }

  handleSelectPlateModal(element: any): void {
    let e = findById(Interactions.iOfferKeys.plateInputTextModal).value,
      t = findById(Interactions.iOfferKeys.stateNumberInputModal).value;
    e &&
      t &&
      e.length > 5 &&
      (showFlex(findById(Interactions.iOfferKeys.searchVinViewModal)),
      this.getVinFromPlates());
  }

  handleVehicleWritingModal(element: any): void {
    findById(Interactions.iOfferKeys.vehicleInputTextModal)?.value &&
      findById(Interactions.iOfferKeys.vehicleInputTextModal)?.value?.length >
        2 &&
      showFlex(findById(Interactions.iOfferKeys.searchVinViewModal));
    // missing this (ymmtTypingTimer = setTimeout(getYmmt, doneTypingIntervalYmmt) on line 345, instead of 445
    this.handleVehicleInputModal(
      findById(Interactions.iOfferKeys.vehicleInputTextModal)
    );
  }
}
