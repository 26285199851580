import { BaseInteractionState, ChatReady, DialogState } from "../../index";
import {
  fadeIn,
  fadeOut,
  findById,
  findBySelector,
  setContent,
  toggleCheckbox,
} from "../../../../../../../service/dom/dom_utilities";

import AppConfig from "../../../../../app.config";
import DialogStateError from "./dialog_state_error";
import Interactions from "../../../interactions";
import LeadTypes from "../../../../../enum/lead_types";
import { isString, isUndefined } from "../../../../../../../service/lang";
import State from "../../../../../state/state";
import Offer from "../../../../../model/offer";

export abstract class BaseDialog
  extends BaseInteractionState
  implements DialogState
{
  private _iOfferData: any | undefined;
  private _agreeSms: boolean | undefined;
  private step1Completed = false;

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  get agreeSms(): boolean {
    if (isUndefined(this._agreeSms)) {
      return false;
    }
    return this._agreeSms;
  }

  private setAgreeSms(data: any): void {
    if (typeof data === "boolean") {
      this._agreeSms = data;
    } else {
      console.error("Invalid data type for _agreeSms: expected boolean.");
    }
  }

  get iOfferData(): any {
    if (isUndefined(this._iOfferData)) {
      return "";
    }
    return this._iOfferData;
  }

  private setIOfferData(data: any): void {
    this._iOfferData = data;
  }

  get leadType(): LeadTypes {
    throw DialogStateError.undefinedLeadType();
  }

  constructor() {
    super();
    this.componentDidMount();
    this.setupBeforeUnloadListener();
  }

  componentDidMount() {
    const onMessage = (event: any) => {
      console.log("EVENT FROM iOffer: ", event);
      this.setIOfferData(event?.data?.data ?? event?.data?.values);
      this.markIOfferFlowCompleted();
    };
    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    }
  }

  close(): void {
    this.removeContainer();
    this.switchChatState(ChatReady);
  }

  closeTerms(): void {
    fadeOut(findBySelector(Interactions.PopupSelectors.termsAndConsText));
  }

  protected disableSubmitButton(): void {
    this.service.disable(Interactions.PopupSelectors.submitButton);
  }

  protected enableSubmitButton(): void {
    this.service.enable(Interactions.PopupSelectors.submitButton);
  }

  formatPhone(element: any): void {
    element.value = this.service.formatPhoneNumber(element?.value);
  }

  protected hideError(): void {
    fadeOut(findBySelector(Interactions.PopupSelectors.error));
  }

  protected markVisitorAsConverted(): void {
    this.storage.setVisitorConverted();
  }

  protected markIOfferFlowCompleted(): void {
    this.storage.setIOfferFlowCompleted();
  }

  protected notifyFailure(): void {
    throw DialogStateError.undefinedFailureCallback();
  }

  protected notifyGoogleAnalytics(): void {
    this.notifier.notifyGoogleAnalyticsLead();
  }

  protected notifySuccess(): void {
    throw DialogStateError.undefinedSuccessCallback();
  }

  openTerms(): void {
    fadeIn(findBySelector(Interactions.PopupSelectors.termsAndConsText));
  }

  protected removeContainer(): void {
    this.service.remove(Interactions.ModalSelectors.container);
  }

  protected scheduleClose(): void {
    const callback = () => this.removeContainer();
    const timeout = AppConfig.instance.offerCloseTimeout.toMilliseconds();
    this.service.schedule(callback, timeout);
  }

  protected showError(error: string): void {
    const element = findBySelector(Interactions.PopupSelectors.error);
    setContent(element, error);
    fadeIn(element);
  }

  protected showSuccess(): void {
    fadeIn(findBySelector(Interactions.PopupSelectors.success));
  }

  protected startButtonLoader(): void {
    this.service.addClass(
      Interactions.PopupSelectors.submitButton,
      Interactions.StyleKeys.buttonLoading
    );
  }

  protected stopButtonLoader(): void {
    this.service.removeClass(
      Interactions.PopupSelectors.submitButton,
      Interactions.StyleKeys.buttonLoading
    );
  }

  clickTerms(event: any): void {
    const termsCheckbox = findById(Interactions.iOfferKeys.termsCheckbox);
    // toggleCheckbox(termsCheckbox, this.setAgreeSms);
    termsCheckbox.addEventListener("click", (e: any) => {
      e.stopPropagation();
      this.setAgreeSms(e?.target?.checked);
    });
  }

  private setupBeforeUnloadListener() {
    window.addEventListener("beforeunload", this.handleBeforeUnload.bind(this));
  }

  private handleBeforeUnload(event: any): void {
    if (this.step1Completed) {
      event.preventDefault();
      console.log("Window is about to be closed or refreshed.");

      //For modern browsers to show the default dialog
      event.returnValue = true;
      console.log("iOffer data before submit: ", this.iOfferData);
      this.submit();
    }
  }

  submitMultiOneForm(offer?: Offer): void {
    fadeOut(findById(Interactions.PopupSelectors.multiphaseOne));
    fadeIn(findById(Interactions.PopupSelectors.multiphaseTwo));
    this.step1Completed = true;

    const image =
      offer?.multiphaseAsset?.objectId ?? offer?.template?.assetObjectId ?? "";
    let imageContent = findBySelector(Interactions.PopupSelectors.content);
    let newImage = `<img editable="" data="" class="pi_pop_up_bg pi_pop_up_bg-generated-by-offer" src="${AppConfig.instance.urlAssets}/${image}" alt=". Enter your information below!">`;
    setContent(imageContent, newImage);

    fadeIn(imageContent);
  }

  protected formatDateString(dateString: any) {
    // Create a new Date object from the input date string
    let date = new Date(dateString);
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear().toString();
    let formattedDate = day + month + year;
    return formattedDate;
  }

  submit(): Promise<void> {
    let iOfferNewObj = { ...this?.iOfferData };
    let vehicleObj = {
      isDrivable: iOfferNewObj.isDriveable,
      externalCondition: iOfferNewObj?.conditions?.ConditionsExterior,
      internalCondition: iOfferNewObj?.conditions?.ConditionsInterior,
      mechanicalCondition: iOfferNewObj?.conditions?.ConditionsMechanical,
      specColor: iOfferNewObj?.conditions?.Color,
      trim: iOfferNewObj?.trim,
      year: iOfferNewObj?.year,
      model: iOfferNewObj?.model,
      make: iOfferNewObj?.make,
      vehicleDesc: iOfferNewObj?.vehicleDescription,
      vehicleMiles: iOfferNewObj?.mileage,
      bodyStyle: null,
      plateNumber: null,
    };
    let customerObj = {
      email: iOfferNewObj?.email,
      firstName: iOfferNewObj?.firstName,
      lastName: iOfferNewObj?.lastName,
      phone: iOfferNewObj?.phone,
      zipcode: iOfferNewObj?.zipcode,
    };
    iOfferNewObj.vehicle = vehicleObj;
    iOfferNewObj.PIDealerId = this.state?.dealershipId;
    iOfferNewObj.PIVisitorId = this.state?.visitorId;
    iOfferNewObj.appraisalId = iOfferNewObj?.appraisalsId;
    iOfferNewObj.customer = customerObj;
    iOfferNewObj.iOfferDealerId = iOfferNewObj?.dealersId;
    iOfferNewObj.offerPrice = iOfferNewObj?.myOfferPrice;
    iOfferNewObj.dateTime = this.formatDateString(iOfferNewObj?.completeDate);
    iOfferNewObj.referenceId = null;
    iOfferNewObj.expDate = this.formatDateString(iOfferNewObj.expDate);

    delete iOfferNewObj.isError;
    delete iOfferNewObj.trim;
    delete iOfferNewObj.make;
    delete iOfferNewObj.model;
    delete iOfferNewObj.year;
    delete iOfferNewObj.trimName;
    delete iOfferNewObj.conditions;
    delete iOfferNewObj.trimName;
    delete iOfferNewObj.mileage;
    delete iOfferNewObj.vehicleDescription;
    delete iOfferNewObj.completeDate;
    delete iOfferNewObj.firstName;
    delete iOfferNewObj.lastName;
    delete iOfferNewObj.phone;
    delete iOfferNewObj.email;
    delete iOfferNewObj.zipcode;
    delete iOfferNewObj.dealersId;
    delete iOfferNewObj.exactId;
    delete iOfferNewObj.myOfferPrice;
    delete iOfferNewObj.appraisalsId;
    delete iOfferNewObj.isDriveable;

    return new Promise((resolve) => {
      const data = this.service.getFormData();
      const error = this.service.validateFormData(data);
      if (isString(error)) {
        this.showError(error);
        resolve();
      } else {
        this.disableSubmitButton();
        this.startButtonLoader();
        this.service
          .submitLead(
            this.leadType,
            {
              ...data,
              iOfferWebBannerData: this.iOfferData ? iOfferNewObj : null,
              agreeToReceiveSms: this.agreeSms ?? false,
            },
            this.offerEngagement
          )
          .then((result) => {
            this.markVisitorAsConverted();
            this.logger.info("Successfully submitted lead:", result);
            this.step1Completed = false;
            window.removeEventListener(
              "beforeunload",
              this.handleBeforeUnload.bind(this)
            );
            this.hideError();
            this.suspend();
            this.showSuccess();
            this.scheduleClose();
            this.notifySuccess();
            this.notifyGoogleAnalytics();
            resolve();
            location.reload();
          })
          .catch((error) => {
            this.step1Completed = false;
            window.removeEventListener(
              "beforeunload",
              this.handleBeforeUnload.bind(this)
            );
            this.logger.error("Error submitting lead:", error);
            this.enableSubmitButton();
            this.stopButtonLoader();
            this.showError(error?.message);
            resolve();
          });
      }
    });
  }
}
