import { isUndefined } from "./lang";

//Factory class is designed to manage the creation and caching of instances of other classes
export default class Factory {
  private static _instance: Factory;
  private _cache: Map<any, any> | undefined;

  private constructor() {}

  //The class has a private _cache property that stores instances of classes. The cache getter initializes the cache if it is undefined
  private get cache(): Map<any, any> {
    if (isUndefined(this._cache)) {
      this._cache = new Map();
    }

    return this._cache;
  }

  //The Factory class uses the singleton pattern to ensure that only one instance of the factory exists
  static get instance(): Factory {
    if (isUndefined(this._instance)) {
      this._instance = new Factory();
    }

    return this._instance;
  }

  //The build method creates an instance of the specified class (type).
  // If useCache is true, it checks the cache first and returns the cached instance if it exists.
  // Otherwise, it creates a new instance, caches it, and then returns it.
  build<T>(type: { new (): T }, useCache: boolean = true): T {
    let product: T;

    if (useCache) {
      if (!this.cache.has(type)) {
        const instance: T = new type();
        this.cache.set(type, instance);
      }

      product = this.cache.get(type);
    } else {
      product = new type();
    }

    return product;
  }

  //The callback method binds a function to a specific context (thisArg) and caches the bound function.
  // If the function is already cached, it returns the cached version.
  callback(type: Function, thisArg: any): Function {
    if (!this.cache.has(type)) {
      this.cache.set(type, type.bind(thisArg));
    }

    return this.cache.get(type);
  }
}
