import { BasePullup, PullupShown, PullupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PullupReady extends BasePullup implements PullupState {
  protected handleShown(offer: Offer): void {
    this.stateBuilder.setOfferId(offer.externalRefId);
    this.stateBuilder.setPullupShown(true);
    this.stateBuilder.setMaximumSessionLength(offer.maximumSessionLength);
  }

  /**
   * @override
   */
  openPullup(_offer: Offer): void {
    this.logger.warning("PullupInitial: Pullup not shown yet!");
  }

  /**
   * @override
   */
  showPullup(offer: Offer): void {
    super.showPullup(offer);
    this.startSession(offer);
    this.handleShown(offer);
    this.switchPullupState(PullupShown);
  }

  protected startSession(offer: Offer): void {
    this.service.startPullupSession(offer.pullupSessionDuration);
  }
}
