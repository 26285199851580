import {
  BasePopup,
  ChatAccepting,
  DialogPopup,
  OfferAccepting,
  PopupShown,
  PopupState,
} from "../../index";

import Offer from "../../../../../model/offer";

export class PopupReady extends BasePopup implements PopupState {
  protected handleShown(offer: Offer): void {
    this.stateBuilder.incrementShownOffersCount();
    this.stateBuilder.setOfferId(offer.externalRefId);
    this.stateBuilder.setPopupShown(true);
    this.stateBuilder.setMaximumSessionLength(offer.maximumSessionLength);
  }

  /**
   * @override
   */
  showPopup(offer: Offer): void {
    super.showPopup(offer);
    this.handleShown(offer);
    this.switchChatState(ChatAccepting);
    this.switchDialogState(DialogPopup);
    this.switchOfferState(OfferAccepting);
    this.switchPopupState(PopupShown);
  }
}
