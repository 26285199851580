import {
  findById,
  findBySelector,
  hide,
  setContent,
  show,
} from "../../../../../../../service/dom/dom_utilities";
import AppConfig from "../../../../../app.config";
import Offer from "../../../../../model/offer";
import State from "../../../../../state/state";
import Interactions from "../../../interactions";
import { BaseInteractionState } from "../base_interaction_state";
import { SctaState } from "./scta_state";

export abstract class BaseScta
  extends BaseInteractionState
  implements SctaState
{
  private eventListenerAdded: boolean = false;
  protected removeContainer(): void {
    this.service.remove(Interactions.ModalSelectors.container);
  }

  sendSctaGoogleAnalyticks(ascEvent: string): void {
    this.notifier.sendSctaGoogleAnalyticksLead(ascEvent);
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  openNeutral(offer: Offer): void {
    const content = this.service.composeScta(offer);
    this.service.pop(content);
    if (
      this.state?.dealershipId &&
      this.state?.customItems?.vin &&
      this?.service?.isVDP
    ) {
      //getting image from inventory
      this.http
        .get({
          url: AppConfig.instance.getVehicleImageByVin(
            this.state.dealershipId,
            this.state?.customItems?.vin
          ),
        })
        .then((response) => {
          const imageSrcFromInventory = response?.data?.imageUrl;
          const image = findById(Interactions.SctaKeys.sctaImage);
          image.src = imageSrcFromInventory;
        });
    }
    show(findBySelector(Interactions.SctaSelectors.sctaOffer));
    hide(findBySelector(Interactions.PopupSelectors.sctaMainContainer));
  }

  showSctaNeutral(offer: Offer): void {
    const content = offer?.neutralButtonSource ?? "";
    this.service.showSctaBtn(content);
  }

  protected addExpandListener(fullText: string): void {
    document.addEventListener("click", (event: any) => {
      const expandLink = findById("expand-text");
      if (event.target && event.target.id === "expand-text") {
        event.preventDefault();
        if (expandLink) {
          const span = expandLink.previousElementSibling;
          if (span) {
            span.textContent = fullText; // Set the full text
            expandLink.style.display = "none"; // Hide the link
          }
        }
      }
    });
  }

  //to be defined
  openTriggered(offer: Offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
    const termsAndConditions =
      offer.termsAndConditions ?? AppConfig.instance.locale.offerTermsAndCons;

    if (offer?.template?.multiphaseEnabled) {
      const multiphaseText = offer?.template?.multiphaseText ?? "";
      const truncatedText =
        multiphaseText.length > 50
          ? `<span class="truncated-text">${multiphaseText.substring(
              0,
              50
            )}...</span> <a href="#" id="expand-text">Read more</a>`
          : multiphaseText;

      setContent(
        findById(Interactions.iOfferKeys.termsText),
        `
                  ${truncatedText}
                <a href="" class="pi_pop_up_terms_and_conditions_link pi_pop_up_terms_and_conditions_link-generated-by-offer">Terms&nbsp;&amp;&nbsp;Conditions</a>
                <div class="pi_pop_up_terms_and_conditions_text pi_pop_up_terms_and_conditions_text-generated-by-offer" hidden="">
                    <button type="button" class="pi_pop_up_terms_and_conditions_close_button pi_pop_up_terms_and_conditions_close_button-generated-by-offer">×</button>
                    <div class="pi_terms_and_conditions_replace pi_terms_and_conditions_replace-generated-by-offer">${termsAndConditions}</div>
                </div>`
      );
      // Add event listener to expand text
      if (!this.eventListenerAdded) {
        this.addExpandListener(multiphaseText);
        this.eventListenerAdded = true; // Update the flag
      }
    }
  }

  showSctaTriggered(offer: Offer): void {
    const content = offer?.triggeredButtonSource ?? "";
    this.service.showSctaBtn(content);
  }

  closeNeutral(): void {
    hide(findBySelector(Interactions.SctaSelectors.sctaOffer));
    this.removeContainer();
  }
}
