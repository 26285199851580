import {
  ChatState,
  ChatSuspended,
  DialogState,
  DialogSuspended,
  InteractionMachine,
  OfferState,
  OfferSuspended,
  PopupState,
  PopupSuspended,
  PullupState,
  PullupSuspended,
} from "../index";

import BaseInteractionService from "../../base/base_interaction_service";
import BaseLogger from "../../../../../../service/logger/base/base_logger";
import BaseNotifier from "../../../../service/notifier/base/base_notifier";
import BaseStateBuilder from "../../../../state/base/base_state_builder";
import BaseStorageRegistrar from "../../../../service/storage/base/base_storage_registrar";
import { ChatOptions } from "./chat/typedef";
import Factory from "../../../../../../service/factory";
import InteractionManager from "../../interaction_manager";
import InteractionService from "../../interaction_service";
import InteractionState from "./interaction_state";
import InteractionStateFactory from "./interaction_state_factory";
import Logger from "../../../../../../service/logger/logger";
import Notifier from "../../../../service/notifier/notifier";
import OfferEngagement from "../../../../model/offer_engagement";
import StateBuilder from "../../../../state/state_builder";
import StorageRegistrar from "../../../../service/storage/storage_registrar";
import { isUndefined } from "../../../../../../service/lang";
import HTTPService from "../../../../../../service/http/http_service";
import BaseHTTPService from "../../../../../../service/http/base/base_http_service";

export abstract class BaseInteractionState implements InteractionState {
  private _context: InteractionManager | undefined;
  private _logger: Logger | undefined;
  private _machine: InteractionMachine | undefined;
  private _notifier: Notifier | undefined;
  private _service: InteractionService | undefined;
  private _stateBuilder: StateBuilder | undefined;
  private _storage: StorageRegistrar | undefined;
  private _http: HTTPService | undefined;

  protected get http(): HTTPService {
    if (isUndefined(this._http)) {
      this._http = Factory.instance.build(BaseHTTPService);
    }

    return this._http;
  }

  get chatEngagement(): ChatOptions | undefined {
    return this.machine.chatOptions;
  }

  get context(): InteractionManager {
    if (isUndefined(this._context)) {
      throw new Error("Context not set!");
    }

    return this._context;
  }

  get hasChatEngagement(): boolean {
    return this.machine.hasChatEngagement;
  }

  get hasOfferEngagament(): boolean {
    return this.machine.hasOfferEngagement;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  get machine(): InteractionMachine {
    if (isUndefined(this._machine)) {
      throw new Error("Machine not set!");
    }

    return this._machine;
  }

  get notifier(): Notifier {
    if (isUndefined(this._notifier)) {
      this._notifier = Factory.instance.build(BaseNotifier);
    }

    return this._notifier;
  }

  get offerEngagement(): OfferEngagement | undefined {
    return this.machine.offerEngagement;
  }

  protected get service(): InteractionService {
    if (isUndefined(this._service)) {
      this._service = Factory.instance.build(BaseInteractionService);
    }

    return this._service;
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  build(type: { new (): InteractionState }): InteractionState {
    return InteractionStateFactory.build(type, this.context, this.machine);
  }

  clearChatEngagement(): void {
    this.machine.clearChatEngagement();
  }

  clearOfferEngagement(): void {
    this.machine.clearOfferEngagement();
  }

  setContext(update: InteractionManager) {
    this._context = update;
  }

  setMachine(update: InteractionMachine) {
    this._machine = update;
  }

  setOfferEngagement(update: OfferEngagement): void {
    this.machine.setOfferEngagement(update);
  }

  suspend(): void {
    this.switchChatState(ChatSuspended);
    this.switchDialogState(DialogSuspended);
    this.switchOfferState(OfferSuspended);
    this.switchPopupState(PopupSuspended);
    this.switchPullupState(PullupSuspended);
  }

  switchChatState(update: { new (): ChatState }): void {
    this.machine.switchChatState(update);
  }

  switchDialogState(update: { new (): DialogState }): void {
    this.machine.switchDialogState(update);
  }

  switchOfferState(update: { new (): OfferState }): void {
    this.machine.switchOfferState(update);
  }

  switchPopupState(update: { new (): PopupState }): void {
    this.machine.switchPopupState(update);
  }

  switchPullupState(update: { new (): PullupState }): void {
    this.machine.switchPullupState(update);
  }
}
