import Offer from "../../../../../model/offer";
import { BaseScta } from "./base_scta";
import { SctaState } from "./scta_state";

export class SctaReady extends BaseScta implements SctaState {
  protected handleShown(offer: Offer): void {
    this.stateBuilder.setOfferId(offer.externalRefId);
    this.stateBuilder.setPullupShown(true);
    this.stateBuilder.setMaximumSessionLength(offer.maximumSessionLength);
  }
  /**
   * @override
   */
  openNeutral(offer: Offer): void {
    super.openNeutral(offer);
  }

  /**
   * @override
   */
  showSctaNeutral(offer: Offer): void {
    super.showSctaNeutral(offer);
  }

  /**
   * @override
   */
  openTriggered(offer: Offer): void {
    super.openTriggered(offer);
    this.stateBuilder.incrementShownOffersCount();
  }

  /**
   * @override
   */
  showSctaTriggered(offer: Offer): void {
    super.showSctaTriggered(offer);
    this.handleShown(offer);
  }
}
