import { BaseInteractionState, PopupState } from "../../index";

import Offer from "../../../../../model/offer";
import {
  findById,
  setContent,
} from "../../../../../../../service/dom/dom_utilities";
import Interactions from "../../../interactions";
import AppConfig from "../../../../../app.config";

export abstract class BasePopup
  extends BaseInteractionState
  implements PopupState
{
  showPopup(offer: Offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
    const termsAndConditions =
      offer.termsAndConditions ?? AppConfig.instance.locale.offerTermsAndCons;

    if (offer?.template?.multiphaseEnabled) {
      const multiphaseText = offer?.template?.multiphaseText ?? "";
      const truncatedText =
        multiphaseText.length > 50
          ? `<span class="truncated-text">${multiphaseText.substring(
              0,
              50
            )}...</span> <a href="#" id="expand-text">Read more</a>`
          : multiphaseText;

      setContent(
        findById(Interactions.iOfferKeys.termsText),
        `
              ${truncatedText}
            <a href="" class="pi_pop_up_terms_and_conditions_link pi_pop_up_terms_and_conditions_link-generated-by-offer">Terms&nbsp;&amp;&nbsp;Conditions</a>
            <div class="pi_pop_up_terms_and_conditions_text pi_pop_up_terms_and_conditions_text-generated-by-offer" hidden="">
                <button type="button" class="pi_pop_up_terms_and_conditions_close_button pi_pop_up_terms_and_conditions_close_button-generated-by-offer">×</button>
                <div class="pi_terms_and_conditions_replace pi_terms_and_conditions_replace-generated-by-offer">${termsAndConditions}</div>
            </div>`
      );

      // Add event listener to expand text
      this.addExpandListener(multiphaseText);
    }
  }
  protected addExpandListener(fullText: string): void {
    document.addEventListener("click", (event: any) => {
      const expandLink = document.getElementById("expand-text");
      if (event.target && event.target.id === "expand-text") {
        event.preventDefault();
        if (expandLink) {
          const span = expandLink.previousElementSibling;
          if (span) {
            span.textContent = fullText; // Set the full text
            expandLink.style.display = "none"; // Hide the link
          }
        }
      }
    });
  }
}
