import {
  ChatState,
  DialogState,
  OfferState,
  PopupState,
  PullupState,
} from "./index";
import Offer from "../../../model/offer";
import InteractionManager from "../interaction_manager";
import OfferEngagement from "../../../model/offer_engagement";
import InteractionState from "./states/interaction_state";
import ChatWidget from "./states/chat/widget/chat_widget";
import { ChatOptions } from "./states/chat/typedef";
import { SctaState } from "./states/single_cta/scta_state";

export interface InteractionMachine {
  get chat(): ChatState;
  get chatOptions(): ChatOptions | undefined;
  get chatWidget(): ChatWidget;
  get context(): InteractionManager;
  get dialog(): DialogState;
  get hasChatEngagement(): boolean;
  get hasOfferEngagement(): boolean;
  get offer(): OfferState;
  get offerEngagement(): OfferEngagement | undefined;
  get popup(): PopupState;
  get pullup(): PullupState;
  get scta(): SctaState;

  acceptChat(): void;

  askChatPermission(engagement: ChatOptions): void;

  buildState(type: { new (): InteractionState }): InteractionState;

  clearChatEngagement(): void;

  clearChatWidget(): void;

  clearOfferEngagement(): void;

  close(): void;

  closeChatNotify(): void;

  closeTerms(): void;

  continueChat(engagement: ChatOptions): void;

  denyChat(): void;

  destroyChat(): void;

  formatPhone(element: any): void;

  handleVehicleInputModal(element: any): void;

  handleVinInputModal(element: any): void;

  handlePlateInputModal(element: any): void;

  handleSelectPlateModal(element: any): void;

  handleVehicleWritingModal(element: any): void;

  joinChat(engagement: ChatOptions): Promise<void>;

  openPullup(offer: Offer): void;

  openNeutral(offer?: Offer): void;

  closeNeutral(): void;

  openTriggered(offer?: Offer): void;

  openTerms(): void;

  openVehicleTabModal(offer?: Offer): void;

  openVinTabModal(offer?: Offer): void;

  openPlateTabModal(offer?: Offer): void;

  closeModal(): void;

  selectItemModal(e: any): void;

  sendChatMessage(message: string): Promise<void>;

  setChatEngagement(update: ChatOptions): void;

  setChatState(update: ChatState): void;

  setChatWidget(update: ChatWidget): void;

  setContext(update: InteractionManager): void;

  setDialogState(update: DialogState): void;

  setOfferEngagement(update: OfferEngagement): void;

  setOfferState(update: OfferState): void;

  setPopupState(update: PopupState): void;

  setPullupState(update: PullupState): void;

  showOffer(engagement: OfferEngagement): void;

  showPopup(offer: Offer): void;

  showPullup(offer: Offer): void;

  showSctaNeutral(offer: Offer): void;

  showSctaTriggered(offer: Offer): void;

  showScheduled(offer: Offer): void;

  submitForm(): void;

  sendSctaGoogleAnalyticks(ascEvent: string): void;

  clickTerms(event: any): void;

  submitMultiOneForm(offer?: Offer): void;

  switchChatState(update: { new (): ChatState }): void;

  switchDialogState(update: { new (): DialogState }): void;

  switchMachine(type: { new (): InteractionMachine }): void;

  switchOfferState(update: { new (): OfferState }): void;

  switchPopupState(update: { new (): PopupState }): void;

  switchPullupState(update: { new (): PullupState }): void;
}
