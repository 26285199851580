import { isObject } from "../../../../service/lang";
import SingleCtaButton from "../scta_buttons";

export class BaseSctaButtons implements SingleCtaButton {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get color(): string | undefined {
    return this._json?.[BaseAssetKeys.color];
  }

  get text(): string | undefined {
    return this._json?.[BaseAssetKeys.text];
  }

  get href(): string | undefined {
    return this._json?.[BaseAssetKeys.href];
  }

  get fontColor(): string | undefined {
    return this._json?.[BaseAssetKeys.fontColor];
  }

  get fontFamily(): string | undefined {
    return this._json?.[BaseAssetKeys.fontFamily];
  }

  get singleCtaAscEvent(): string | undefined {
    return this._json?.[BaseAssetKeys.singleCtaAscEvent];
  }
}

export class BaseAssetKeys {
  static readonly color: string = "color";
  static readonly text: string = "text";
  static readonly href: string = "href";
  static readonly fontColor: string = "fontColor";
  static readonly fontFamily: string = "fontFamily";
  static readonly singleCtaAscEvent: string = "singleCtaAscEvent";
}
